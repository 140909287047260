.pdf {
    background-color: #fff;
    padding: 0.5em;
    width: 400px;
}

.pdf-topbar {
    justify-content: space-between;
}

.pdf h2 {
    font-weight: 800;
}

.pdf p, .pdf span {
    font-weight: 600;
}

.grid-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.grid-images img {
    width: 80%;
    height: auto;
}

.signature-pad {
    margin-top: 2rem;
    border: 1px solid var(--orange-color);
    border-radius: 5px;
}

.signature-pad-buttons {
    justify-content: flex-end;
}

.signature-pad-buttons button {
    width: 110px;
    margin: 7px;
}

img.signature {
    margin-top: 10px;
    width: 200px;
    height: auto;
}

.delete-hour {
    background-color: var(--dark-color);
    color: white;
    padding-right: 3px;
    padding-left: 3px;
    padding-top: 1px;
    padding-bottom: 1px;
    border: 1px transparent solid;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
}