.app__workers {
    margin: 1rem;
    color: #fff;
}

button.new-worker {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    color: #fff;
}

button.new-worker h2 {
    font-weight: 600;
}

.create-worker {
    padding: 0.7em;
}

.close-popup {
    display: flex;
    justify-content: flex-end;
}

.close-popup h4 {
    margin: 0.5rem;
}
