:root {
  --dark-color: #231f20;
  --orange-color: #e88039;
  --green-color: #8cc63e;
  --purple-color: #d70a77;
  --gray-color: #939192;
}

html {
  overflow-x: hidden;
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-color);
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

textarea, input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

textarea::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}


a {
  color: none;
  text-decoration: none;
}

button {
  background-color: transparent;
  border: 1px solid var(--orange-color);
  border-radius: 5px;
  color: none;
  transition: all 0.5s ease-in-out;
}

button:hover {
  background-color: var(--orange-color);
}

a:hover, button:hover {
  cursor: pointer;
}

input {
  border: 1px solid var(--orange-color);
  border-radius: 5px;
  padding: 0.5em;
  background-color: var(--dark-color);
  color: var(--orange-color);
}

input:focus {
  outline: none;
}

::-webkit-calendar-picker-indicator {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}


.align-items-center {
  display: flex;
  align-items: center;
}

.app__layout {
  margin: 1rem;
  color: #fff;
}

button.add {
  background-color: var(--green-color);
  color: #fff;
  border-radius: 100px;
}

.checkbox {
  border: none;
  height: 1rem;
  width: 1rem;
  border-radius: 10px;
  transition: none !important;
}

.active {
  background-color: var(--green-color);
}

.active:hover {
  background-color: var(--green-color);
}


.disabled {
  border: 1px solid #fff;
  background-color: transparent;
}

.disabled:hover {
  background-color: transparent;
}

.app__popup {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__popup-children {
  width: 95%;
  background-color: var(--dark-color);
  border-radius: 5px;
  box-shadow: 1px 1px var(--dark-color);
}

.green-button {
  width: 100%;
  background-color: var(--green-color);
  border: none;
  color: white;
}

.red-button {
  width: 100%;
  background-color: red;
  border: none;
  color: white;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}