.app__report-ore {
    margin: 1rem;
    color: #fff;
}

table {
    border-collapse: collapse;
}

h4 {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 390px){
    h4.date {
        font-size: 0.6em;
    }
}