.app__project-page {
    margin: 1rem;
    color: #fff;
}

.attached-picture {
    background-color: #343534;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    max-width: 20vw;
    min-width: 20vw;
    height: 20vw;
    border-radius: 10px;
    z-index: 10; /* Make the other pictures to scroll behind this*/
}

.attached-picture:hover {
    cursor: pointer;
}

.attached-picture span, .attached-picture label {
    font-size: 3.5rem;
    padding-bottom: 0.2em;
}

.images-gallery {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: center;
}

.delete-icon {
    height: 1.2em;
    width: 1.2em;
    background-color: #343534;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    position: relative;
    bottom: 8.3vw;
    right: 1.5vw;
}

.delete-icon span {
    font-size: 1.2em;
    padding: 0.6em;
}

.delete-icon:hover {
    cursor: pointer;
}